import React from "react";
import InsuranceLawImg from "../../assets/images/insurance-law.svg";
import EducationLawImg from "../../assets/images/education-law.svg";
import CriminalLawImg from "../../assets/images/criminal-law.svg";
import CommercialLawImg from "../../assets/images/commercial-law.svg";
import ServiceCard from "../components/ServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

const Services = () => {
	return (
		<div className="py-[100px] w-full bg-troo-stateSecondary">
			<div className="main-container-big flex h-full flex-col items-center gap-8">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">
						Lawyer Services For Public & Many Company
					</h2>
				</div>
				<div className="w-full h-full box-border">
					<Swiper
						slidesPerView={1}
						spaceBetween={0}
						pagination={{
							clickable: true,
						}}
						loop={true}
						autoplay={{
							delay: 2000,
							disableOnInteraction: false,
						}}
						breakpoints={{
							640: {
								slidesPerView: 1,
							},
							768: {
								slidesPerView: 2,
							},
							1024: {
								slidesPerView: 3,
							},
							1280: {
								slidesPerView: 4,
							},
						}}
						className="mySwiper"
						modules={[Grid, Pagination, Autoplay]}
					>
						<SwiperSlide>
							<ServiceCard img={InsuranceLawImg} title="Insurance Law" />
						</SwiperSlide>
						<SwiperSlide>
							<ServiceCard img={EducationLawImg} title="Education Law" />
						</SwiperSlide>
						<SwiperSlide>
							<ServiceCard img={CriminalLawImg} title="Criminal Law" />
						</SwiperSlide>
						<SwiperSlide>
							<ServiceCard img={CommercialLawImg} title="Commercial Law" />
						</SwiperSlide>
						<SwiperSlide>
							<ServiceCard img={InsuranceLawImg} title="Insurance Law" />
						</SwiperSlide>
						<SwiperSlide>
							<ServiceCard img={EducationLawImg} title="Education Law" />
						</SwiperSlide>
						<SwiperSlide>
							<ServiceCard img={CriminalLawImg} title="Criminal Law" />
						</SwiperSlide>
						<SwiperSlide>
							<ServiceCard img={CommercialLawImg} title="Commercial Law" />
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default Services;
