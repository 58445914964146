import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./../../assets/images/logo-hr.svg";
import logoDark from "./../../assets/images/logo-dark.svg";
import Button from "./Button";
import { HiOutlineMenu } from "react-icons/hi";
import { FiChevronDown } from "react-icons/fi";
import { NAV_ROUTES } from "../constants/navRoutes";
import { ROUTES } from "../constants/routes";
import AccordianMenu from "./AccordianMenu";

const Navbar = () => {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);
	return (
		<div className="drawer w-full sticky top-0 bg-troo-stateSecondary text-troo-stateTertiary z-50">
			<input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col">
				<div className="w-full navbar flex items-center justify-between main-container py-0 gap-4">
					<div className="flex items-center py-[22px]">
						<Link to={ROUTES.home}>
							<img
								src={logo}
								alt="troolaw"
								className="w-[150px] xl:w-[170px] 2xl:w-[250px]"
							/>
						</Link>
					</div>

					<div className="hidden lg:flex items-center gap-4 lg:gap-6 2xl:gap-8 h-full">
						{NAV_ROUTES.map((route, i) => {
							if (!route?.isMultiple) {
								return (
									<Link
										key={i}
										to={route.path}
										className={`${
											location.pathname === route.path && "activeNav"
										} relative h-full flex items-center justify-center hover:after:absolute hover:after:top-0 hover:after:content-[''] hover:after:w-[2px] hover:after:h-9 hover:after:bg-troo-primary hover:after:animate-smoothHeight`}
									>
										{route.name}
									</Link>
								);
							} else {
								return (
									<div
										key={i}
										className="dropdown dropdown-hover h-full flex items-center"
									>
										<label tabIndex={0} className="flex items-center gap-1">
											<span>{route.name}</span>
											<FiChevronDown className="text-troo-stateTertiary" />
										</label>
										<ul
											tabIndex={0}
											className="dropdown-content p-0 bg-troo-stateTertiary top-16 2xl:top-24 z-[1] menu shadow rounded"
										>
											{route.subPages.map((subPage, i) => {
												return (
													<li
														key={i}
														className="bg-troo-stateTertiary hover:bg-troo-stateSecondary text-troo-stateSecondary hover:text-troo-stateTertiary"
													>
														<Link
															to={subPage.path}
															className="hover:text-troo-stateTertiary px-6 py-3 text-sm xl:text-base"
														>
															{subPage.name}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								);
							}
						})}
					</div>

					<button className="block lg:hidden">
						<label htmlFor="my-drawer-3">
							<HiOutlineMenu size={30} className="cursor-pointer" />
						</label>
					</button>

					<Button
						link={ROUTES.requestConsultation}
						className="hidden xl:flex btn-theme-primary"
					>
						Request a Consultation
					</Button>
				</div>
			</div>
			<div className="drawer-side">
				<label htmlFor="my-drawer-3" className="drawer-overlay"></label>
				<div className="menu p-4 w-[80%] md:w-80 h-full bg-base-200 text-troo-secondary">
					{/* Sidebar content here */}
					<div className="flex flex-col items-start gap-10 lg:gap-6 2xl:gap-8">
						<div className="flex items-center">
							<Link href={ROUTES.home}>
								<img
									src={logoDark}
									alt="troolaw"
									className="w-[150px] md:w-[200px] xl:w-[250px]"
								/>
							</Link>
						</div>
						<div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
							{NAV_ROUTES.map((route, i) => {
								if (!route?.isMultiple) {
									return (
										<Link
											key={i}
											to={route.path}
											className={`py-1 ${
												location.pathname === route.path && "activeMobileNav"
											}`}
										>
											{route.name}
										</Link>
									);
								} else {
									return (
										<AccordianMenu
											key={i}
											title={route.name}
											subMenu={route.subPages}
										/>
									);
								}
							})}
						</div>
						<Button
							link={ROUTES.requestConsultation}
							className="self-center flex btn-theme-secondary"
						>
							Request a Consultation
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
