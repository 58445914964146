import React from "react";
import Layout from "../shared/components/Layout";
import Button from "../shared/components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../shared/constants/routes";

const ErrorPage = () => {
	return (
		<Layout errorPage>
			<div className="w-full bg-troo-secondary flex items-center justify-center h-screen">
				<div className="main-container-big flex flex-col items-center justify-center gap-16">
					<div className="flex flex-col items-center justify-center">
						<h1 className="text-troo-primary text-opacity-20 text-9xl md:text-[250px] lg:text-[300px]">
							404
						</h1>
						<h2 className="text-troo-stateTertiary -mt-20 md:-mt-32">
							Page Not Found
						</h2>
					</div>
					<Button className="flex btn-theme-primary">
						<Link to={ROUTES.home}>Back to HomePage</Link>
					</Button>
				</div>
			</div>
		</Layout>
	);
};

export default ErrorPage;
