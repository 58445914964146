import React from "react";
import Layout from "../shared/components/Layout";
import BlogGridSingleRow from "../shared/sections/BlogGridSingleRow";
import BlogDetailsSection from "../shared/sections/BlogDetailsSection";

const BlogDetails = () => {
	return (
		<Layout>
			<BlogDetailsSection />
			<BlogGridSingleRow />
		</Layout>
	);
};

export default BlogDetails;
