import React from "react";
import Button from "./Button";

const ConsultationForm = () => {
	return (
		<form className="bg-troo-statePrimary flex w-full flex-col gap-6 p-6 md:p-10 lg:p-14">
			<h3 className="text-troo-stateTertiary">Book A Free Consultation</h3>
			<div className="flex flex-col items-center gap-4 w-full">
				<input
					type="text"
					placeholder="Full Name"
					className="w-full bg-transparent placeholder-troo-stateTertiary border border-troo-stateTertiary px-6 py-3 outline-none"
				/>
				<input
					type="email"
					placeholder="Email"
					className="w-full bg-transparent placeholder-troo-stateTertiary border border-troo-stateTertiary px-6 py-3 outline-none"
				/>
				<input
					type="text"
					placeholder="Phone"
					className="w-full bg-transparent placeholder-troo-stateTertiary border border-troo-stateTertiary px-6 py-3 outline-none"
				/>
				<select className="select select-bordered w-full text-troo-stateTertiary bg-transparent border outline-none rounded-none px-6 font-normal">
					<option value="Choose Law">Choose Law</option>
					<option value="Insurance law">Insurance law</option>
					<option value="Criminal Law">Criminal Law</option>
					<option value="Medical Law">Medical Law</option>
				</select>
				<textarea
					placeholder="Message"
					rows={4}
					className="w-full bg-transparent placeholder-troo-stateTertiary border border-troo-stateTertiary px-6 py-3 outline-none"
				/>
				<Button type="submit" className="flex btn-theme-white">
					Submit
				</Button>
			</div>
		</form>
	);
};

export default ConsultationForm;
