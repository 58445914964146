import React from "react";
import TeamCard from "../components/TeamCard";
import TeamAvatar1 from "../../assets/images/team-avatar-1.png";
import TeamAvatar2 from "../../assets/images/team-avatar-2.png";
import TeamAvatar3 from "../../assets/images/team-avatar-3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

const ExpertTeam = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex h-full flex-col items-center gap-8">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">Expert In The Filed</h2>
				</div>
				<div className="w-full h-full box-border">
					<Swiper
						slidesPerView={1}
						spaceBetween={16}
						pagination={{
							clickable: true,
						}}
						loop={true}
						autoplay={{
							delay: 5000,
							disableOnInteraction: false,
						}}
						breakpoints={{
							640: {
								slidesPerView: 1,
							},
							768: {
								slidesPerView: 2,
							},
							1024: {
								slidesPerView: 3,
							},
						}}
						className="mySwiper slider-overflow-visible"
						modules={[Grid, Pagination, Autoplay]}
					>
						<SwiperSlide>
							<TeamCard
								img={TeamAvatar1}
								name="Chris Jariko"
								designation="Founder and CEO"
								desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisi
            lectus, suscipit ac ipsum id, eleifend eleifend neque. Morbi vehicula
            mauris vel felis lacinia luctus. Suspendisse suscipit id magna sit
            amet rhoncus. Donec vel mauris purus."
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								img={TeamAvatar3}
								name="Alina Kevin"
								designation="Junior Attorney"
								desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisi
					lectus, suscipit ac ipsum id, eleifend eleifend neque. Morbi vehicula
					mauris vel felis lacinia luctus. Suspendisse suscipit id magna sit
					amet rhoncus. Donec vel mauris purus."
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								img={TeamAvatar2}
								name="Brean Lanthe"
								designation="Senior Attorney"
								desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisi
					lectus, suscipit ac ipsum id, eleifend eleifend neque. Morbi vehicula
					mauris vel felis lacinia luctus. Suspendisse suscipit id magna sit
					amet rhoncus. Donec vel mauris purus."
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								img={TeamAvatar1}
								name="Chris Jariko"
								designation="Founder and CEO"
								desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisi
            lectus, suscipit ac ipsum id, eleifend eleifend neque. Morbi vehicula
            mauris vel felis lacinia luctus. Suspendisse suscipit id magna sit
            amet rhoncus. Donec vel mauris purus."
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								img={TeamAvatar3}
								name="Alina Kevin"
								designation="Junior Attorney"
								desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisi
					lectus, suscipit ac ipsum id, eleifend eleifend neque. Morbi vehicula
					mauris vel felis lacinia luctus. Suspendisse suscipit id magna sit
					amet rhoncus. Donec vel mauris purus."
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								img={TeamAvatar2}
								name="Brean Lanthe"
								designation="Senior Attorney"
								desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisi
					lectus, suscipit ac ipsum id, eleifend eleifend neque. Morbi vehicula
					mauris vel felis lacinia luctus. Suspendisse suscipit id magna sit
					amet rhoncus. Donec vel mauris purus."
							/>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default ExpertTeam;
