import React, { useState } from "react";
import SliderImg from "../../assets/images/tabsliderimg.png";

const TabSlider = () => {
	const [activeTab, setActiveTab] = useState("blp");
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex h-full flex-col items-center gap-8">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">Step Close To Justice</h2>
				</div>
				<div className="flex items-center flex-col lg:flex-row gap-10">
					<img src={SliderImg} alt="Slider img" className="w-full lg:w-1/2" />
					<div className="w-full flex-grow flex flex-col items-start gap-8">
						<div className="flex flex-col sm:flex-row items-center sm:flex-wrap gap-4 w-full sm:w-auto text-troo-stateTertiary">
							<div
								className={`${
									activeTab === "blp" && "bg-troo-statePrimary"
								} py-[8px] px-[14px] items-center cursor-pointer bg-transparent w-full sm:w-auto  text-center`}
								onClick={() => setActiveTab("blp")}
							>
								<p>Best Law Practice</p>
							</div>
							<div
								className={`${
									activeTab === "et" && "bg-troo-statePrimary"
								} py-[8px] px-[14px] items-center cursor-pointer bg-transparent w-full sm:w-auto text-center`}
								onClick={() => setActiveTab("et")}
							>
								<p>Efficiency & Trust</p>
							</div>
							<div
								className={`${
									activeTab === "ryd" && "bg-troo-statePrimary"
								} py-[8px] px-[14px] items-center cursor-pointer bg-transparent w-full sm:w-auto text-center`}
								onClick={() => setActiveTab("ryd")}
							>
								<p>Result You Deserve</p>
							</div>
						</div>
						<div className="flex flex-col items-start gap-4 text-troo-stateTertiary px-[14px]">
							{activeTab === "blp" && (
								<>
									<h3>Best Law Practices</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
										nisi lectus, suscipit ac ipsum id, eleifend eleifend neque.
										Morbi vehicula mauris vel felis lacinia luctus. Suspendisse
										suscipit id magna sit amet rhoncus. Donec vel mauris purus.
										Nulla tempus lobortis lacus ut fermentum. Morbi sit amet
										tincidunt nulla. Curabitur commodo fringilla purus, sit amet
										posuere erat. Etiam efficitur justo nec est porta, sed
										congue metus lacinia. In pellentesque risus id interdum
										condimentum. Orci varius natoque penatibus et magnis
									</p>
								</>
							)}
							{activeTab === "et" && (
								<>
									<h3>Efficiency & Trust</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
										nisi lectus, suscipit ac ipsum id, eleifend eleifend neque.
										Morbi vehicula mauris vel felis lacinia luctus. Suspendisse
										suscipit id magna sit amet rhoncus. Donec vel mauris purus.
										Nulla tempus lobortis lacus ut fermentum. Morbi sit amet
										tincidunt nulla. Curabitur commodo fringilla purus, sit amet
										posuere erat. Etiam efficitur justo nec est porta, sed
										congue metus lacinia. In pellentesque risus id interdum
										condimentum. Orci varius natoque penatibus et magnis
									</p>
								</>
							)}
							{activeTab === "ryd" && (
								<>
									<h3>Result You Deserve</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
										nisi lectus, suscipit ac ipsum id, eleifend eleifend neque.
										Morbi vehicula mauris vel felis lacinia luctus. Suspendisse
										suscipit id magna sit amet rhoncus. Donec vel mauris purus.
										Nulla tempus lobortis lacus ut fermentum. Morbi sit amet
										tincidunt nulla. Curabitur commodo fringilla purus, sit amet
										posuere erat. Etiam efficitur justo nec est porta, sed
										congue metus lacinia. In pellentesque risus id interdum
										condimentum. Orci varius natoque penatibus et magnis
									</p>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabSlider;
