import React from "react";

const VideoCard = () => {
	return (
		<div className="flex flex-col w-full">
			<div className="w-full">
				<iframe
					className="w-full h-96"
					src="https://www.youtube.com/embed/tgbNymZ7vqY"
					title="TrooLaw"
				/>
			</div>
			<div className="px-6 py-4 md:px-10 md:py-8 text-troo-secondary flex flex-col gap-2 bg-troo-primary w-full">
				<h4>An Interview With Raymond King</h4>
				<p>
					Etiam rutrum, ligula vitae mollis dictum, libero eros euismod purus ,
					pretium facilisis ligula
				</p>
			</div>
		</div>
	);
};

export default VideoCard;
