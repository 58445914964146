import React from "react";
import Blog1 from "../../assets/images/blog-1.png";
import Blog2 from "../../assets/images/blog-2.png";
import Blog3 from "../../assets/images/blog-3.png";
import BlogSmall from "./BlogSmall";

const RecentNews = () => {
	return (
		<div className="flex flex-col items-start bg-troo-stateSecondary gap-6 px-8 py-8 w-full">
			<h5 className="text-troo-stateTertiary">Recent News</h5>
			<BlogSmall
				img={Blog1}
				title="Fusce placerat dignissim viverra. Nam faucibus hendrerit"
			/>
			<BlogSmall
				img={Blog2}
				title="Etiam in tellus varius, mattis nisi a, blandit nisl. Nam facilisis"
			/>
			<BlogSmall
				img={Blog3}
				title="In scelerisque neque non augue porttitor ultrices. Nam lobortis nibh"
			/>
		</div>
	);
};

export default RecentNews;
