import React from "react";
import Content from "../components/Content";
import Quote from "../components/Quote";
import BlogDetailImg from "../../assets/images/blog-detail-1.png";
import { AiOutlineTag } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import Search from "../components/Search";
import CategoryList from "../components/CategoryList";
import RecentNews from "../components/RecentNews";
import FollowSocialMedia from "../components/FollowSocialMedia";
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
} from "react-icons/fa";

const BlogDetailsSection = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex flex-col lg:flex-row h-full items-start gap-6">
				<div className="w-full lg:w-[70%] flex flex-col items-start gap-8">
					<div className="w-full h-full overflow-hidden">
						<img
							src={BlogDetailImg}
							alt="blog detail"
							className="w-full h-full"
						/>
					</div>
					<div className="flex items-center gap-5">
						<div className="flex items-center text-troo-stateTertiary gap-2">
							<AiOutlineTag className="text-2xl" />
							<p>Established Fact</p>
						</div>
						<div className="flex items-center text-troo-stateTertiary gap-2">
							<BsPerson className="text-2xl" />
							<p>William C. Dunn</p>
						</div>
					</div>
					<Content
						headBig
						heading="H1 Suspendisse ultrices tortor eget sodales congue. Ut sit amet suscipit nulla."
						desc={
							<>
								<p>
									Aenean vel lacinia felis, et mattis leo. Phasellus elementum
									pretium libero. Cras vel pharetra eros, et iaculis augue.
									Proin malesuada, ante a luctus commodo, libero mi accumsan
									turpis, id placerat est nisi in ligula. Vivamus at orci ante.
									Ut mollis turpis lacinia, viverra ex a, porttitor lorem. Nunc
									tincidunt sem nisi, eget tempus felis condimentum quis. Lorem
									ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit
									amet finibus elit, vitae euismod eros. Suspendisse consectetur
									dolor nec dolor facilisis, ut viverra lacus blandit. Duis
									semper felis vel ante lacinia, non aliquam urna hendrerit. Sed
									volutpat felis eu dui ullamcorper, non consectetur lorem
									ultrices.
								</p>
								<p>
									Quisque est nibh, cursus ut nunc blandit, tincidunt cursus
									nibh. Proin pharetra ligula tristique nisi hendrerit laoreet.
									In hac habitasse platea dictumst. Suspendisse potenti. Ut
									sagittis vitae nulla non volutpat. Mauris tempor euismod
									purus, lacinia fringilla risus finibus tempus. Nunc
									pellentesque eros eget tincidunt tristique. Suspendisse velit
									enim, viverra et mattis vel, tincidunt at odio. Orci varius
									natoque penatibus et magnis dis parturient montes, nascetur
									ridiculus mus. Suspendisse cursus rutrum sapien, vel consequat
									felis hendrerit tempor. Phasellus lobortis fringilla
									fringilla. Donec suscipit urna sed congue ultricies. Donec
									porttitor volutpat odio, id luctus felis cursus quis. Praesent
									dictum ultrices egestas. Sed vehicula felis ex, eget aliquam
									elit elementum sed. Quisque a lectus a quam venenatis euismod.
								</p>
								<ul className="list-disc list-inside">
									<li>Phasellus tempus diam luctus enim dictum feugiat.</li>
									<li>
										Nam ut leo vel elit vulputate tempor sit amet at lorem.
									</li>
									<li>Nulla pellentesque sem vestibulum dapibus volutpat.</li>
									<li>Suspendisse dignissim eros in tempor cursus.</li>
									<li>
										Maecenas fringilla nunc tempus, bibendum tellus id, sodales
										nisl.
									</li>
								</ul>
							</>
						}
					/>
					<Content
						img={BlogDetailImg}
						heading="H2 onec neque neque, pellentesque at mauris pharetra, pharetra finibus urna. Donec ultrices nisl quis"
						desc={
							<>
								<p>
									Donec ut nunc convallis, suscipit arcu at, tempor mauris.
									Morbi vel massa quam. Aenean placerat diam nibh, nec ultrices
									sapien tristique sit amet. Etiam nec sagittis tortor, ornare
									pellentesque lorem. Pellentesque habitant morbi tristique
									senectus et netus et malesuada fames ac turpis egestas.
									Suspendisse tortor diam, maximus id commodo nec, rhoncus ut
									mi. Pellentesque mollis pharetra mi et pretium. Suspendisse
									facilisis nisl vitae vestibulum congue. Pellentesque non sem
									malesuada, pellentesque nisi id, sagittis quam. Curabitur
									maximus, velit sit amet viverra scelerisque, mi justo molestie
									elit, sed fringilla dolor neque at lorem. Nunc sapien lectus,
									condimentum eu velit nec, varius condimentum felis. Maecenas
									suscipit elit vel lacus viverra placerat.
								</p>
								<ul className="list-decimal list-inside">
									<li>Phasellus tempus diam luctus enim dictum feugiat.</li>
									<li>
										Nam ut leo vel elit vulputate tempor sit amet at lorem.
									</li>
									<li>Nulla pellentesque sem vestibulum dapibus volutpat.</li>
									<li>Suspendisse dignissim eros in tempor cursus.</li>
									<li>
										Maecenas fringilla nunc tempus, bibendum tellus id, sodales
										nisl.
									</li>
								</ul>
							</>
						}
					/>
					<Quote
						text="Vestibulum vel tortor sollicitudin, imperdiet ante at, hendrerit nulla. Nullam eu arcu iaculis quam sodales ornare. Maecenas ac neque eget magna tempus aliquam nec in purus. Cras sed nisi ullamcorper, accumsan neque vitae, vulputate purus. Etiam id purus sapien. Proin at nulla ac sapien sagittis finibus"
						author="Charles I. Hatfield"
					/>
					<Content
						heading="Nulla facilisi. Proin pellentesque"
						desc={
							<>
								<p>
									Aliquam leo orci, elementum eu placerat sed, mollis a dui.
									Praesent odio diam, pharetra pellentesque ligula vel, mollis
									facilisis felis. Fusce luctus metus a condimentum malesuada.
									Curabitur ornare fermentum suscipit. In mollis elit a magna
									tincidunt blandit. Proin iaculis bibendum erat eleifend
									cursus. In hac habitasse platea dictumst. Morbi consectetur
									dictum turpis eget mollis.
								</p>
							</>
						}
					/>
					<div className="flex items-center bg-troo-stateSecondary gap-4 px-8 py-8 w-full">
						<h6 className="text-troo-stateTertiary">Share</h6>
						<div className="flex items-center gap-3">
							<div className="p-2 bg-troo-stateTertiary text-troo-secondary rounded-full">
								<FaFacebookF />
							</div>
							<div className="p-2 bg-troo-stateTertiary text-troo-secondary rounded-full">
								<FaTwitter />
							</div>
							<div className="p-2 bg-troo-stateTertiary text-troo-secondary rounded-full">
								<FaInstagram />
							</div>
							<div className="p-2 bg-troo-stateTertiary text-troo-secondary rounded-full">
								<FaLinkedinIn />
							</div>
						</div>
					</div>
				</div>
				<div className="w-full md:sticky top-40 lg:w-[30%] flex flex-col items-start gap-4">
					<Search />
					<CategoryList />
					<RecentNews />
					<FollowSocialMedia />
				</div>
			</div>
		</div>
	);
};

export default BlogDetailsSection;
