import React from "react";

const ContactCard = ({ icon, title, desc }) => {
	return (
		<div className="flex w-full items-center gap-4 py-8 border-b border-troo-stateTertiary border-opacity-25">
			<div className="w-16 bg-troo-primary text-troo-secondary text-3xl h-16 rounded-full overflow-hidden flex items-center justify-center">
				{icon}
			</div>
			<div className="flex flex-col items-start text-troo-stateTertiary">
				<h5>{title}</h5>
				{desc}
			</div>
		</div>
	);
};

export default ContactCard;
