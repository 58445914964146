import { ROUTES } from "./routes";

export const NAV_ROUTES = [
	{
		path: ROUTES.home,
		name: "Home",
	},
	{
		path: ROUTES.aboutUs,
		name: "About Us",
	},
	{
		path: ROUTES.practiceArea,
		name: "Practice Areas",
	},
	{
		path: ROUTES.attorneys,
		name: "Attorneys",
	},
	{
		name: "Blog",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.blogsGrid,
				name: "Blog Grid",
			},
			{
				path: ROUTES.singleBlogDetail,
				name: "Single Blog Detail",
			},
		],
	},
	{
		name: "Pages",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.singlePracticeDetail,
				name: "Single Practice Detail",
			},
			{
				path: ROUTES.faq,
				name: "FAQ",
			},
			{
				path: ROUTES.requestConsultation,
				name: "Request a Consultation",
			},
			{
				path: ROUTES.singleAttorney,
				name: "Single Attorney",
			},
			{
				path: ROUTES.errorPage,
				name: "404 Error Page",
			},
		],
	},
	{
		path: "/contact-us",
		name: "Contact Us",
	},
];
