import React from "react";

const EmergencyCall = () => {
	return (
		<div className="bg-troo-secondary">
			<div className="main-container-big py-[50px] bg-troo-secondary">
				<div className=" text-troo-stateTertiary py-[60px] px-8 bg-troo-stateSecondary flex items-center justify-center">
					<h3 className="flex flex-col items-center md:block">
						<span>Emergency? Call Us Now </span>{" "}
						<span className="text-troo-primary">+35 123 456 789</span>
					</h3>
				</div>
			</div>
		</div>
	);
};

export default EmergencyCall;
