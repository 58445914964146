import React from "react";
import BlogCard from "../components/BlogCard";
import BlogImg1 from "../../assets/images/blog-1.jpg";
import BlogImg2 from "../../assets/images/blog-2.jpg";
import BlogImg3 from "../../assets/images/blog-3.jpg";

const OurBlogs = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex h-full flex-col items-center gap-16">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">Our Blogs</h2>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
					<BlogCard
						img={BlogImg1}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg2}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg3}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
				</div>
			</div>
		</div>
	);
};

export default OurBlogs;
