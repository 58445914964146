import React from "react";
import Timeline from "../components/Timeline";

const History = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex h-full flex-col items-center gap-16">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">Our History</h2>
				</div>
				<Timeline />
			</div>
		</div>
	);
};

export default History;
