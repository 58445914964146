import React from "react";
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
} from "react-icons/fa";

const FollowSocialMedia = () => {
	return (
		<div className="flex flex-col items-start bg-troo-stateSecondary gap-4 px-8 py-8 w-full">
			<h5 className="text-troo-stateTertiary">Follow Us</h5>
			<div className="flex items-center gap-3">
				<div className="p-2 bg-troo-stateTertiary text-troo-secondary rounded-full">
					<FaFacebookF />
				</div>
				<div className="p-2 bg-troo-stateTertiary text-troo-secondary rounded-full">
					<FaTwitter />
				</div>
				<div className="p-2 bg-troo-stateTertiary text-troo-secondary rounded-full">
					<FaInstagram />
				</div>
				<div className="p-2 bg-troo-stateTertiary text-troo-secondary rounded-full">
					<FaLinkedinIn />
				</div>
			</div>
		</div>
	);
};

export default FollowSocialMedia;
