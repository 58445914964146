import React from "react";
import Layout from "../shared/components/Layout";
import SubBanner from "../shared/components/SubBanner";
import BlogGridMultipleRows from "../shared/sections/BlogGridMultipleRows";
import BlogGridSingleRow from "../shared/sections/BlogGridSingleRow";

const BlogGrid = () => {
	return (
		<Layout>
			<SubBanner title="Blogs Grid" className="bg-blog-banner" />
			<BlogGridSingleRow growGrid />
			<BlogGridMultipleRows />
		</Layout>
	);
};

export default BlogGrid;
