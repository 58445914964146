import React, { useState } from "react";
import { Link } from "react-router-dom";

const AccordianMenu = ({ title, subMenu }) => {
	const [isActive, setIsActive] = useState(false);

	const handleAccordionClick = (e) => {
		setIsActive(e.target.checked && !isActive);
	};

	return (
		<div
			className={`collapse collapse-arrow min-h-0 p-0 py-1 cursor-pointer rounded-none ${
				isActive ? " collapse-open" : "  collapse-close"
			}`}
		>
			<input
				type="radio"
				name="accordian"
				className="p-0"
				onClick={handleAccordionClick}
			/>
			<p className="collapse-title height-0 p-0 text-sm md:text-base">
				{title}
			</p>
			<div className="collapse-content p-0 text-base">
				<div className="flex flex-col bg-troo-stateSecondary bg-opacity-10 mt-2 py-2 px-4">
					{subMenu?.map((menu, i) => {
						return (
							<Link
								key={i}
								to={menu.path}
								className="hover:text-troo-primary text-sm py-2"
							>
								{menu.name}
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default AccordianMenu;
