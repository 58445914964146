import React from "react";
import Layout from "../shared/components/Layout";
import SubBanner from "../shared/components/SubBanner";
import ReviewSlider from "../shared/sections/ReviewSlider";
import OurClients from "../shared/sections/OurClients";
import FaqMultiColumn from "../shared/sections/FaqMultiColumn";

const Faq = () => {
	return (
		<Layout>
			<SubBanner title="FAQ" className="bg-faq-banner" />
			<FaqMultiColumn />
			<ReviewSlider />
			<OurClients />
		</Layout>
	);
};

export default Faq;
