import React from "react";
import ConsultationForm from "../components/ConsultationForm";
import ContactCard from "../components/ContactCard";
import { GoLocation } from "react-icons/go";
import { HiOutlineMail, HiOutlineClock } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";

const ContactUsConsultation = () => {
	return (
		<div
			className={`py-[100px] w-full bg-troo-secondary bg-right-top bg-no-repeat`}
		>
			<div className="main-container-big flex flex-col lg:flex-row gap-8">
				<div className="lg:w-[40%] flex flex-col items-start justify-center h-full">
					<ContactCard
						icon={<GoLocation />}
						title="Location"
						desc={
							<p>
								1503 Upland Avenue ,Arcadia,
								<br /> New Mexico 08219
							</p>
						}
					/>
					<ContactCard
						icon={<HiOutlineMail />}
						title="Email"
						desc={
							<p>
								hellosuppot@gmail.com
								<br /> help@gmail.com
							</p>
						}
					/>
					<ContactCard
						icon={<BsTelephone />}
						title="Phone Number"
						desc={
							<p>
								(+642) 245 356 432
								<br />
								(+642) 245 356 432
							</p>
						}
					/>
					<ContactCard
						icon={<HiOutlineClock />}
						title="Working Hours"
						desc={
							<p>
								Sunday - Monday
								<br />
								09 am - 05 pm
							</p>
						}
					/>
				</div>
				<div className="lg:w-[60%]">
					<ConsultationForm />
				</div>
			</div>
		</div>
	);
};

export default ContactUsConsultation;
