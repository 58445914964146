import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FooterConsultationCard from "./FooterConsultationCard";
const Layout = ({ children, errorPage }) => {
	return (
		<>
			<Navbar />
			<main>{children}</main>
			<div className="bg-troo-secondary w-full h-18 sm:h-24 md:h-36 lg:h-48" />
			{!errorPage && (
				<div className="relative flex flex-col items-center">
					<Footer />
					<div className="absolute -top-[8%] sm:-top-[10%] md:-top-[15%] lg:-top-[20%] w-full">
						<FooterConsultationCard />
					</div>
				</div>
			)}
		</>
	);
};

export default Layout;
