import React from "react";

const Timeline = () => {
	return (
		<div className=" bg-troo-secondary py-6 flex flex-col justify-center sm:py-12 w-full">
			<div className="py-3 sm:mx-auto px-2 sm:px-0 w-full">
				<div className="relative  antialiased">
					<div className="block w-[2px] bg-troo-statePrimary absolute h-full md:left-1/2 transform -translate-x-1/2"></div>

					<div className="mt-6 sm:mt-0 sm:mb-12">
						<div className="flex flex-row items-center">
							<div className="flex flex-col-reverse md:flex-row justify-start w-full mx-auto items-start md:items-center">
								<div className="w-full md:w-1/2 md:pr-8">
									<div className="p-4 bg-transparent">
										<h5 className="text-troo-primary">
											It is a long established fact that a reader
										</h5>
										<p className="text-troo-stateTertiary">
											There are many variations of passages of Lorem Ipsum
											available, but the majority have suffered alteration in
											some form, by injected humour, or randomised words which
											don't look
										</p>
									</div>
								</div>
								<div className="md:pl-8 ml-4 md:ml-0">
									<div className="px-2 bg-troo-primary">
										<h3 className="text-troo-secondary">2008</h3>
									</div>
								</div>
							</div>
							<div className="rounded-full bg-troo-primary w-3 h-3 outline outline-1 outline-troo-primary outline-offset-4 absolute md:left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
						</div>
					</div>

					<div className="mt-6 sm:mt-0 sm:mb-12">
						<div className="flex flex-col sm:flex-row sm:items-center items-start justify-center sm:justify-start">
							<div className="flex flex-col md:flex-row justify-start md:justify-end w-full mx-auto items-start md:items-center">
								<div className="md:pr-8 md:pl-8 ml-4 md:ml-0">
									<div className="px-2 bg-troo-stateTertiary">
										<h3 className="text-troo-secondary">2011</h3>
									</div>
								</div>
								<div className="w-full md:w-1/2 md:pl-8">
									<div className="p-4 bg-transparent">
										<h5 className="text-troo-primary">
											It is a long established fact that a reader
										</h5>
										<p className="text-troo-stateTertiary">
											There are many variations of passages of Lorem Ipsum
											available, but the majority have suffered alteration in
											some form, by injected humour, or randomised words which
											don't look
										</p>
									</div>
								</div>
							</div>
							<div className="rounded-full bg-troo-stateTertiary w-3 h-3 outline outline-1 outline-troo-stateTertiary outline-offset-4 absolute md:left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
						</div>
					</div>

					<div className="mt-6 sm:mt-0 sm:mb-12">
						<div className="flex flex-row items-center">
							<div className="flex flex-col-reverse md:flex-row justify-start w-full mx-auto items-start md:items-center">
								<div className="w-full md:w-1/2 md:pr-8">
									<div className="p-4 bg-transparent">
										<h5 className="text-troo-primary">
											It is a long established fact that a reader
										</h5>
										<p className="text-troo-stateTertiary">
											There are many variations of passages of Lorem Ipsum
											available, but the majority have suffered alteration in
											some form, by injected humour, or randomised words which
											don't look
										</p>
									</div>
								</div>
								<div className="md:pl-8 ml-4 md:ml-0">
									<div className="px-2 bg-troo-stateTertiary">
										<h3 className="text-troo-secondary">2008</h3>
									</div>
								</div>
							</div>
							<div className="rounded-full bg-troo-stateTertiary w-3 h-3 outline outline-1 outline-troo-stateTertiary outline-offset-4 absolute md:left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
						</div>
					</div>

					<div className="mt-6 sm:mt-0 sm:mb-12">
						<div className="flex flex-col sm:flex-row sm:items-center items-start justify-center sm:justify-start">
							<div className="flex flex-col md:flex-row justify-start md:justify-end w-full mx-auto items-start md:items-center">
								<div className="md:pr-8 md:pl-8 ml-4 md:ml-0">
									<div className="px-2 bg-troo-stateTertiary">
										<h3 className="text-troo-secondary">2011</h3>
									</div>
								</div>
								<div className="w-full md:w-1/2 md:pl-8">
									<div className="p-4 bg-transparent">
										<h5 className="text-troo-primary">
											It is a long established fact that a reader
										</h5>
										<p className="text-troo-stateTertiary">
											There are many variations of passages of Lorem Ipsum
											available, but the majority have suffered alteration in
											some form, by injected humour, or randomised words which
											don't look
										</p>
									</div>
								</div>
							</div>
							<div className="rounded-full bg-troo-stateTertiary w-3 h-3 outline outline-1 outline-troo-stateTertiary outline-offset-4 absolute md:left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Timeline;
