import React from "react";
import Button from "./Button";

const PricingCard = ({ title, price, desc }) => {
	return (
		<div className="flex flex-col">
			<div className="text-center bg-troo-primary px-6 py-7 flex flex-col gap-3">
				<h4>{title}</h4>
				<h3>{price}</h3>
			</div>
			<div className="border border-troo-primary px-10 py-7 flex flex-col gap-6 items-center">
				<p className="text-center text-troo-stateTertiary leading-10">{desc}</p>
				<Button className="flex btn-theme-primary">Read More</Button>
			</div>
		</div>
	);
};

export default PricingCard;
