import React from "react";
import BlogCard from "../components/BlogCard";
import Button from "../components/Button";
import BlogImg1 from "../../assets/images/blog-1.jpg";
import BlogImg2 from "../../assets/images/blog-2.jpg";
import BlogImg3 from "../../assets/images/blog-3.jpg";
import BlogImg4 from "../../assets/images/blog-4.jpg";
import BlogImg5 from "../../assets/images/blog-5.jpg";

const BlogGridMultipleRows = () => {
	return (
		<div className="pb-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex h-full flex-col items-center gap-16">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">Recent Blogs</h2>
				</div>
				<div className="flex flex-wrap justify-center items-center gap-3">
					<div className="tab-theme-active">All</div>
					<div className="tab-theme">Management</div>
					<div className="tab-theme">Design</div>
					<div className="tab-theme">Technology</div>
					<div className="tab-theme">People</div>
					<div className="tab-theme">Videos</div>
					<div className="tab-theme">Workspace</div>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
					<BlogCard
						img={BlogImg1}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg2}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg3}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg4}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg5}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg1}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg2}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg3}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
					<BlogCard
						img={BlogImg4}
						title="There are many variations of passages of Lorem Ipsum available"
					/>
				</div>
				<Button className="flex btn-theme-primary mt-10">Load More</Button>
			</div>
		</div>
	);
};

export default BlogGridMultipleRows;
