import React from "react";
import VideoSection from "../shared/sections/VideoSection";
import TabSlider from "../shared/sections/TabSlider";
import ReviewSlider from "../shared/sections/ReviewSlider";
import Consultation from "../shared/sections/Consultation";
import Layout from "../shared/components/Layout";
import ServiceSingleRow from "../shared/sections/ServiceSingleRow";
import ImageContentPosts from "../shared/sections/ImageContentPosts";

const RequestConsultation = () => {
	return (
		<Layout>
			<Consultation bgImg="bg-form-placeholder" noSideImg/>
			<TabSlider />
			<ServiceSingleRow />
			<ImageContentPosts />
			<VideoSection noStats/>
			<ReviewSlider />
		</Layout>
	);
};

export default RequestConsultation;
