import React from "react";
import Layout from "../shared/components/Layout";
import SubBanner from "../shared/components/SubBanner";
import ContactUsConsultation from "../shared/sections/ContactUsConsultation";
import GoogleMap from "../shared/components/GoogleMap";

const ContactUs = () => {
	return (
		<Layout>
			<SubBanner title="Contact Us" className="bg-contact-placeholder" />
			<ContactUsConsultation />
			<GoogleMap />
		</Layout>
	);
};

export default ContactUs;
