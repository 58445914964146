import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import Client1 from "../../assets/images/client-1.png";
import Client2 from "../../assets/images/client-2.png";
import Client3 from "../../assets/images/client-3.png";
import Client4 from "../../assets/images/client-4.png";

const OurClients = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex h-full flex-col items-center gap-16">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">Our Clients</h2>
				</div>
				<div className="w-full h-full box-border">
					<Swiper
						slidesPerView={2}
						spaceBetween={0}
						pagination={{
							clickable: true,
						}}
						loop={true}
						autoplay={{
							delay: 2000,
							disableOnInteraction: false,
						}}
						breakpoints={{
							640: {
								slidesPerView: 2,
							},
							768: {
								slidesPerView: 3,
							},
							1024: {
								slidesPerView: 3,
							},
							1280: {
								slidesPerView: 4,
							},
						}}
						className="mySwiper"
						modules={[Grid, Pagination, Autoplay]}
					>
						<SwiperSlide>
							<div className="flex flex-col items-center justify-center pb-12 w-full h-full">
								<img src={Client1} alt="client1" className="object-contain" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-center justify-center pb-12 w-full h-full">
								<img src={Client2} alt="client2" className="object-contain" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-center justify-center pb-12 w-full h-full">
								<img src={Client3} alt="client3" className="object-contain" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-center justify-center pb-12 w-full h-full">
								<img src={Client4} alt="client4" className="object-contain" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-center justify-center pb-12 w-full h-full">
								<img src={Client2} alt="client2" className="object-contain" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-center justify-center pb-12 w-full h-full">
								<img src={Client3} alt="client3" className="object-contain" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-center justify-center pb-12 w-full h-full">
								<img src={Client4} alt="client4" className="object-contain" />
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default OurClients;
