import React from "react";
import { FiSearch } from "react-icons/fi";

const Search = () => {
	return (
		<div className="flex items-start gap-0 w-full h-14 border border-troo-statePrimary">
			<input
				className="py-5 pl-5 w-[80%] box-border h-full bg-transparent"
				placeholder="Search"
			/>
			<div className="w-[20%] h-full text-xl border border-troo-statePrimary flex items-center justify-center bg-troo-statePrimary text-troo-stateTertiary">
				<FiSearch />
			</div>
		</div>
	);
};

export default Search;
