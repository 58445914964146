import React from "react";

const CategoryList = () => {
	return (
		<div className="text-troo-stateTertiary bg-troo-stateSecondary w-full p-10 flex flex-col gap-4">
			<h5>Category</h5>
			<div className="flex flex-col w-full items-start">
				<span className="w-full text-start py-2">Design Files</span>
				<span className="w-full text-start py-3">People</span>
				<span className="w-full text-start py-3">Technology</span>
				<span className="w-full text-start py-3">Videos</span>
				<span className="w-full text-start py-3">Workspace</span>
				<span className="w-full text-start py-3">Lease Expiry</span>
			</div>
		</div>
	);
};

export default CategoryList;
