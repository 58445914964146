import React from "react";
import Layout from "../shared/components/Layout";
import SubBanner from "../shared/components/SubBanner";
import About from "../shared/sections/About";
import VideoSection from "../shared/sections/VideoSection";
import ExperienceContainer from "../shared/components/ExperienceContainer";
import ExpertTeam from "../shared/sections/ExpertTeam";
import ReviewSlider from "../shared/sections/ReviewSlider";
import OurClients from "../shared/sections/OurClients";
import History from "../shared/sections/History";
import ServiceSingleRow from "../shared/sections/ServiceSingleRow";

const AboutUs = () => {
	return (
		<Layout>
			<SubBanner title="About Us" className="bg-about-banner" />
			<About />
			<ServiceSingleRow />
			<History />
			<div className="relative flex flex-col items-center">
				<VideoSection />
				<div className="absolute -bottom-[50%] sm:-bottom-[40%] md:-bottom-[50%] lg:-bottom-[30%]">
					<ExperienceContainer />
				</div>
			</div>
			<div className="bg-troo-secondary w-full h-72 sm:h-60 md:h-72 lg:h-48" />
			<ExpertTeam />
			<ReviewSlider />
			<OurClients />
		</Layout>
	);
};

export default AboutUs;
