import React from "react";
import { HiPlayCircle } from "react-icons/hi2";

const VideoSection = ({ noStats }) => {
	return (
		<div
			className={`w-full bg-video-banner bg-no-repeat bg-cover flex sm:items-center sm:justify-center px-6 sm:px-8 ${
				noStats ? "py-24" : "h-[636px]"
			}`}
		>
			<div className="main-container-big flex items-start sm:items-center py-8 flex-col lg:flex-row">
				<p className="text-7xl lg:text-8xl xl:text-9xl text-troo-primary">
					<HiPlayCircle />
				</p>
				<h2 className="text-troo-stateTertiary sm:text-center lg:text-left">
					We Are best Law Consultant
					<br /> Professional For Law Firm
				</h2>
			</div>
		</div>
	);
};

export default VideoSection;
