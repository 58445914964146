import React from "react";
import AttorneyList from "../components/AttorneyList";
import TeamCardBig from "../components/TeamCardBig";
import Content from "../components/Content";
import ConsultationForm from "../components/ConsultationForm";
import VideoCard from "../components/VideoCard";

const SingleAttorneyDetails = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex flex-col md:flex-row h-full items-start gap-6">
				<div className="w-full md:w-[30%] md:sticky top-40">
					<AttorneyList />
				</div>
				<div className="w-full md:w-[70%] flex flex-col items-start gap-8">
					<TeamCardBig />
					<Content
						heading="Overview"
						desc={
							<>
								<p>
									A collection of textile samples lay spread out on the table –
									Samsa was a travelling salesman – and above it there hung a
									picture that he had recently cut out of an illustrated
									magazine and housed in a nice, gilded frame. It showed a lady
									fitted out with a fur hat and fur boa who sat upright, raising
									a heavy fur muff that covered the whole of her lower arm
									towards the viewer.
								</p>
								<p>
									Textile samples lay spread out on the table – Samsa was a
									travelling salesman – and above it there hung a picture that
									he had recently cut out of an illustrated magazine and housed
									in a nice, gilded frame. It showed a lady fitted out with a
									fur hat and fur boa .
								</p>
							</>
						}
					/>
					<Content
						heading="Education"
						desc={
							<>
								<p>
									J.D., 1991 – 1994, Darhill dinginge College of Law (Status :
									Excellent – Top 5 of School) – B.S., 1998 – 2003, University
									of Grambell, Usinitation (Status : Excellent)
								</p>
							</>
						}
					/>
					<VideoCard />
					<Content
						heading="Certificate"
						desc={
							<>
								<p>
									1. Peacefully between its four familiar walls collection of
									textile 2011.
								</p>
								<p>2. Depending cretification its four 2015</p>
								<p>2. Tona happend will gamen overview 2016.</p>
							</>
						}
					/>
					<Content
						heading="Etiam rutrum, ligula vitae mollis dictum"
						desc={
							<>
								<p>
									Pellentesque vehicula justo id sollicitudin viverra. Donec ac
									leo non sem efficitur dapibus. Curabitur pellentesque faucibus
									massa. Mauris commodo ac elit vel semper. Sed ante ipsum,
									sodales at orci sed, hendrerit ultrices magna. Duis iaculis,
									massa id imperdiet aliquet, ante tellus volutpat ligula, eu
									porta libero sapien in dui. Curabitur laoreet, orci quis
									laoreet rutrum, ante orci cursus erat, et sodales velit ipsum
									quis erat. Morbi vehicula quis arcu id commodo.
								</p>
							</>
						}
					/>
					<Content
						heading="Nulla facilisi. Proin pellentesque"
						desc={
							<>
								<p>
									Aliquam leo orci, elementum eu placerat sed, mollis a dui.
									Praesent odio diam, pharetra pellentesque ligula vel, mollis
									facilisis felis. Fusce luctus metus a condimentum malesuada.
									Curabitur ornare fermentum suscipit. In mollis elit a magna
									tincidunt blandit. Proin iaculis bibendum erat eleifend
									cursus. In hac habitasse platea dictumst. Morbi consectetur
									dictum turpis eget mollis.
								</p>
							</>
						}
					/>
					<ConsultationForm />
				</div>
			</div>
		</div>
	);
};

export default SingleAttorneyDetails;
