import React from "react";
import Layout from "../shared/components/Layout";
import SubBanner from "../shared/components/SubBanner";
import ReviewSlider from "../shared/sections/ReviewSlider";
import OurClients from "../shared/sections/OurClients";
import SingleAttorneyDetails from "../shared/sections/SingleAttorneyDetails";

const SingleAttorney = () => {
	return (
		<Layout>
			<SubBanner
				title="Single Attorney"
				className="bg-single-attorney-placeholder"
			/>
			<SingleAttorneyDetails />
			<ReviewSlider />
			<OurClients />
		</Layout>
	);
};

export default SingleAttorney;
