import React from "react";
import HeroSection from "../shared/sections/HeroSection";
import About from "../shared/sections/About";
import Services from "../shared/sections/Services";
import VideoSection from "../shared/sections/VideoSection";
import ExperienceContainer from "../shared/components/ExperienceContainer";
import TabSlider from "../shared/sections/TabSlider";
import EmergencyCall from "../shared/sections/EmergencyCall";
import ExpertTeam from "../shared/sections/ExpertTeam";
import WhyUs from "../shared/sections/WhyUs";
import Pricing from "../shared/sections/Pricing";
import ReviewSlider from "../shared/sections/ReviewSlider";
import OurClients from "../shared/sections/OurClients";
import Consultation from "../shared/sections/Consultation";
import OurBlogs from "../shared/sections/OurBlogs";
import Layout from "../shared/components/Layout";

const Home = () => {
	return (
		<Layout>
			<HeroSection />
			<About />
			<Services />
			<div className="relative flex flex-col items-center">
				<VideoSection />
				<div className="absolute -bottom-[50%] sm:-bottom-[40%] md:-bottom-[50%] lg:-bottom-[30%]">
					<ExperienceContainer />
				</div>
			</div>
			<div className="bg-troo-secondary w-full h-72 sm:h-60 md:h-72 lg:h-48" />
			<TabSlider />
			<EmergencyCall />
			<ExpertTeam />
			<WhyUs />
			<Pricing />
			<ReviewSlider />
			<OurClients />
			<Consultation />
			<OurBlogs />
		</Layout>
	);
};

export default Home;
