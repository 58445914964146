import React from "react";
import Layout from "../shared/components/Layout";
import SubBanner from "../shared/components/SubBanner";
import About from "../shared/sections/About";
import ReviewSlider from "../shared/sections/ReviewSlider";
import OurClients from "../shared/sections/OurClients";
import ServiceMultipleRows from "../shared/sections/ServiceMultipleRows";
import ImageContentPosts from "../shared/sections/ImageContentPosts";

const PracticeArea = () => {
	return (
		<Layout>
			<SubBanner title="Practice Areas" className="bg-practice-areas-banner" />
			<ServiceMultipleRows />
			<ImageContentPosts />
			<About withRadialProgress />
			<ReviewSlider />
			<OurClients />
		</Layout>
	);
};

export default PracticeArea;
