import React from "react";
import Breadcrumbs from "./Breadcrumbs";

const SubBanner = ({ title, className }) => {
	return (
		<div
			className={`max-w-full bg-troo-stateSecondary bg-center py-10 md:py-0 h-auto md:h-[418px] ${className} bg-no-repeat`}
		>
			<div className="text-troo-stateTertiary main-container-big flex h-full flex-col items-start justify-center gap-8">
				<h1>{title}</h1>
				<Breadcrumbs />
			</div>
		</div>
	);
};

export default SubBanner;
