import React from "react";
import { BsArrowRight } from "react-icons/bs";

const ServiceCard = ({ img, title, noLink }) => {
	return (
		<div className="service-card w-full transition ease-in-out delay-50 h-full flex flex-col gap-4 p-[40px] hover:bg-troo-primary items-start justify-between border border-troo-primary cursor-pointer">
			<div className="flex flex-col gap-4 items-start">
				<img
					src={img}
					alt={`${title}`}
					className="service-img w-16 lg:w-20 h-16 lg:h-20 transition ease-in-out delay-50"
				/>
				<h4 className="text-troo-stateTertiary service-text transition ease-in-out delay-50">
					{title}
				</h4>
				<p className="text-troo-stateTertiary service-text transition ease-in-out delay-50">
					Nunc in congue nisl, a luctus orci. Aenean velit lorem, vestibulum
					viverra nisi
				</p>
			</div>
			{!noLink && (
				<p className="text-troo-primary service-link flex items-center gap-2 justify-self-end">
					Read More <BsArrowRight />
				</p>
			)}
		</div>
	);
};

export default ServiceCard;
