export const ROUTES = {
	home: "/",
	aboutUs: "/about-us",
	practiceArea: "/practice-areas",
	attorneys: "/attorneys",
	blogsGrid: "/blogs-grid",
	singleBlogDetail: "/blogs-grid/single-blog-detail",
	singlePracticeDetail: "/practice-areas/single-practice-detail",
	faq: "/faq",
	requestConsultation: "/request-a-consultation",
	singleAttorney: "/attorneys/single-attorney",
	errorPage: "/404",
	contactPage: "/contact-us",
};
