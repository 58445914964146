import React, { useEffect, useRef, useState } from "react";
import HandShake from "../../assets/images/hand-shake.svg";
import JusticeLaw from "../../assets/images/law-justice.svg";
import Clients from "../../assets/images/happy-clients.svg";
import Awards from "../../assets/images/awards.svg";
import useCounter from "../hooks/useCounter";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
const ExperienceContainer = () => {
	const ref = useRef(null);
	const { isVisible } = useIntersectionObserver(ref);

	return (
		<div className="main-container bg-transparent" ref={ref}>
			<div className="bg-troo-statePrimary py-[50px] lg:py-[60px] shadow-lg px-16 xl:px-24 box-border flex flex-col items-center gap-12">
				<div className="flex flex-col items-center gap-2">
					<p className="text-troo-stateTertiary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary text-center">
						20 Year Of Experience In Legal Cases Field
					</h2>
				</div>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
					<div className="flex w-full sm:w-auto items-center gap-4 border text-troo-stateTertiary border-troo-stateTertiary px-[20px] py-[14px]">
						<p className="text-5xl">
							<img src={HandShake} alt="Business Partners" />
						</p>
						<div>
							<h4>{useCounter(0, 230, 4000, isVisible)}+</h4>
							<p>Business Partners</p>
						</div>
					</div>
					<div className="flex w-full sm:w-auto items-center gap-4 border text-troo-stateTertiary border-troo-stateTertiary px-[20px] py-[14px]">
						<p className="text-4xl">
							<img src={JusticeLaw} alt="Business Partners" />
						</p>
						<div>
							<h4>{useCounter(0, 180, 4000, isVisible)}+</h4>
							<p>Cases Done</p>
						</div>
					</div>
					<div className="flex w-full sm:w-auto items-center gap-4 border text-troo-stateTertiary border-troo-stateTertiary px-[20px] py-[14px]">
						<p className="text-5xl">
							<img src={Clients} alt="Business Partners" />
						</p>
						<div>
							<h4>{useCounter(0, 350, 4000, isVisible)}+</h4>
							<p>Happy Clients</p>
						</div>
					</div>
					<div className="flex w-full sm:w-auto items-center gap-4 border text-troo-stateTertiary border-troo-stateTertiary px-[20px] py-[14px]">
						<p className="text-5xl">
							<img src={Awards} alt="Business Partners" />
						</p>
						<div>
							<h4>{useCounter(0, 150, 4000, isVisible)}+</h4>
							<p>Awards Win</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExperienceContainer;
