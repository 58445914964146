import React from "react";

const GoogleMap = () => {
	return (
		<iframe
			title="troo law"
			className="w-full"
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.001524964495!2d72.7848282758056!3d21.19209838049845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04d198e284e31%3A0x56c3bab7f1aff804!2sTRooTech%20Business%20Solutions%20Pvt.%20Ltd.%20-%20Surat!5e0!3m2!1sen!2sin!4v1688472941115!5m2!1sen!2sin"
			width="600"
			height="450"
			style={{ border: 0 }}
			allowfullscreen=""
			loading="lazy"
			referrerpolicy="no-referrer-when-downgrade"
		></iframe>
	);
};

export default GoogleMap;
