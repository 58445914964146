import React from "react";
import Button from "./Button";

const HeroSectionSlide = ({ title, headline, btnText }) => {
	return (
		<div className="flex flex-col items-start gap-4 lg:gap-6">
			<p className="text-troo-statePrimary uppercase">
				<span className="font-bold text-lg">//</span> There are many variations
			</p>
			{title}
			{headline}
			{btnText && <Button className="flex btn-theme-primary">{btnText}</Button>}
		</div>
	);
};

export default HeroSectionSlide;
