import React from "react";
import { AiOutlineTag } from "react-icons/ai";

const BlogSmall = ({ img, title }) => {
	return (
		<div className="grid grid-cols-3 gap-4 items-center">
			<div className="w-full h-full overflow-hidden">
				<img src={img} alt="blog" className="w-full h-full object-cover" />
			</div>
			<div className="col-span-2 flex flex-col text-troo-stateTertiary items-start">
				<div className="flex items-center gap-2">
					<AiOutlineTag className="text-lg" />
					<p className="text-sm">Established Fact</p>
				</div>
				<h6 className="line-clamp-2">{title}</h6>
			</div>
		</div>
	);
};

export default BlogSmall;
