import React from "react";

const Content = ({ heading, desc, headBig, img }) => {
	return (
		<div className="flex flex-col items-start text-troo-stateTertiary gap-4">
			{img && (
				<div className="w-full h-full overflow-hidden">
					<img src={img} alt="blog detail" className="w-full h-full" />
				</div>
			)}
			{headBig ? <h1>{heading}</h1> : <h2>{heading}</h2>}
			{desc}
		</div>
	);
};

export default Content;
