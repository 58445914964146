import React from "react";
import Placeholder from "../../assets/images/cta-placeholder.png";
import Button from "./Button";

const FooterConsultationCard = () => {
	return (
		<div className="main-container-big bg-transparent">
			<div className="bg-troo-primary w-full py-12 md:py-0 md:h-64 overflow-hidden shadow-lg pl-8 md:pl-0 pr-8 xl:pr-24 box-border flex items-center gap-16">
				<div className="hidden md:block md:w-1/2 h-full">
					<img
						src={Placeholder}
						alt="placeholder"
						className="w-full h-full object-cover"
					/>
				</div>
				<div className="flex w-full md:w-1/2 flex-col gap-4 items-start">
					<h3 className="text-troo-secondary">
						Speak With Our Expert Lawyers Today!
					</h3>
					<Button className="flex btn-theme-secondary">
						Request a Consultation
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FooterConsultationCard;
