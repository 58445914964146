import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Attorneys from "./pages/Attorneys";
import PracticeArea from "./pages/PracticeArea";
import SingleAttorney from "./pages/SingleAttorney";
import Faq from "./pages/Faq";
import SinglePracticeDetails from "./pages/SinglePracticeDetails";
import { ROUTES } from "./shared/constants/routes";
import BlogGrid from "./pages/BlogGrid";
import ErrorPage from "./pages/404";
import RequestConsultation from "./pages/RequestConsultation";
import ContactUs from "./pages/ContactUs";
import BlogDetails from "./pages/BlogDetails";

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path={ROUTES.home} element={<Home />} />
					<Route path={ROUTES.aboutUs} element={<AboutUs />} />
					<Route path={ROUTES.contactPage} element={<ContactUs />} />
					<Route path={ROUTES.practiceArea} element={<PracticeArea />} />
					<Route path={ROUTES.attorneys} element={<Attorneys />} />
					<Route path={ROUTES.singleAttorney} element={<SingleAttorney />} />
					<Route path={ROUTES.blogsGrid} element={<BlogGrid />} />
					<Route path={ROUTES.faq} element={<Faq />} />
					<Route path={ROUTES.errorPage} element={<ErrorPage />} />
					<Route path={ROUTES.singleBlogDetail} element={<BlogDetails />} />
					<Route
						path={ROUTES.requestConsultation}
						element={<RequestConsultation />}
					/>
					<Route
						path={ROUTES.singlePracticeDetail}
						element={<SinglePracticeDetails />}
					/>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
