import React from "react";
import HeroBanner from "../../assets/images/herobanner.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import HeroSectionSlide from "../components/HeroSectionSlide";

const HeroSection = () => {
	return (
		<div className="py-8 w-full bg-troo-secondary">
			<div className="main-container-big flex flex-col-reverse gap-8 lg:flex-row items-center justify-between">
				<Swiper
					slidesPerView={1}
					pagination={{
						clickable: true,
					}}
					loop={true}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
					}}
					className="mySwiper slider-dot-left w-full"
					modules={[Grid, Pagination, Autoplay]}
				>
					<SwiperSlide>
						<HeroSectionSlide
							btnText="Request a Consultation"
							headline={
								<p className="text-troo-stateTertiary">
									It is a long established fact that a reader will be distracted
									by the readable content of a page when looking at its layout.
									The point of using Lorem Ipsum is that it has a more-or-less
									normal
								</p>
							}
							title={
								<h1 className="text-troo-stateTertiary">
									Contrary to popular
									<br />
									belief, Lorem Ipsum
									<br />
									is not simply random
								</h1>
							}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<HeroSectionSlide
							headline={
								<p className="text-troo-stateTertiary">
									It is a long established fact that a reader will be distracted
									by the readable content of a page when looking at its layout.
									The point of using Lorem Ipsum is that it has a more-or-less
									normal
								</p>
							}
							title={
								<h1 className="text-troo-stateTertiary">
									Contrary to popular
									<br />
									belief, Lorem Ipsum
									<br />
									is not simply random
								</h1>
							}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<HeroSectionSlide
							btnText="Request a Consultation"
							headline={
								<p className="text-troo-stateTertiary">
									It is a long established fact that a reader will be distracted
									by the readable content of a page when looking at its layout.
									The point of using Lorem Ipsum is that it has a more-or-less
									normal
								</p>
							}
							title={
								<h1 className="text-troo-stateTertiary">
									Contrary to popular
									<br />
									belief, Lorem Ipsum
									<br />
									is not simply random
								</h1>
							}
						/>
					</SwiperSlide>
				</Swiper>
				<img
					src={HeroBanner}
					alt="herobanner"
					className="w-11/12 md:w-10/12 lg:w-1/2"
				/>
			</div>
		</div>
	);
};

export default HeroSection;
