import React from "react";
import Placeholder from "../../assets/images/placeholder-2.jpg";
import { AiOutlineTag } from "react-icons/ai";
import { IoPersonOutline } from "react-icons/io5";
import { HiArrowLongRight } from "react-icons/hi2";
import { AiOutlineClockCircle } from "react-icons/ai";
import { ROUTES } from "../constants/routes";
import { Link } from "react-router-dom";

const BlogCard = ({ img, title }) => {
	return (
		<div className="group flex flex-col items-center w-full">
			<div className="relative w-full h-64">
				<Link to={ROUTES.singleBlogDetail}>
					<img
						src={img || Placeholder}
						alt="Placeholder"
						className="w-full h-full object-cover"
					/>
				</Link>
				<div className="text-troo-stateTertiary flex items-center gap-2 absolute top-2 left-2 bg-troo-statePrimary px-3 py-2">
					<p className="text-xl">
						<AiOutlineClockCircle />
					</p>
					<p>10 Min</p>
				</div>
			</div>
			<div className="w-full relative bg-troo-stateSecondary group-hover:bg-troo-primary text-troo-stateTertiary group-hover:text-troo-stateSecondary transition-colors px-8 border-t-4 border-troo-statePrimary flex flex-col items-start py-10 gap-6">
				<div className="absolute flex flex-col items-center justify-center leading-5 bg-troo-statePrimary -top-[13%] rounded-full overflow-hidden right-6 w-[62px] h-[62px] aspect-square">
					<span>18</span>
					<span>July</span>
				</div>
				<div className="flex items-center justify-between w-full">
					<div className="flex items-center gap-2">
						<AiOutlineTag />
						<p>Established Fact</p>
					</div>
					<div className="flex items-center gap-2">
						<IoPersonOutline />
						<p>William C. Dunn</p>
					</div>
				</div>
				<h5 className="line-clamp-2">
					{title ||
						"There are many variations of passages of Lorem Ipsum available"}
				</h5>
				<div className="flex items-center gap-2 text-troo-primary group-hover:text-troo-stateSecondary">
					<p>Read More</p>
					<p className="text-3xl">
						<HiArrowLongRight />
					</p>
				</div>
			</div>
		</div>
	);
};

export default BlogCard;
