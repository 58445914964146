import React from "react";
import PricingCard from "../components/PricingCard";

const Pricing = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex h-full flex-col items-center gap-8">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">Anwalt Pricing Tables</h2>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 2xl:gap-12 justify-between w-full">
					<PricingCard
						title="Immigration Law"
						price="$790"
						desc="Nunc facilisis lacus ut lorem Aenean tincidunt metus a dui semper Sed congue nulla id fringilla Duis aliquet lacus facilisis urna Curabitur posuere lorem a sem pulvinar Suspendisse feugiat lorem nec Integer quis urna id mi maximus"
					/>
					<PricingCard
						title="Criminal Law"
						price="$560"
						desc="Nunc facilisis lacus ut lorem Aenean tincidunt metus a dui semper Sed congue nulla id fringilla Duis aliquet lacus facilisis urna Curabitur posuere lorem a sem pulvinar Suspendisse feugiat lorem nec Integer quis urna id mi maximus"
					/>
					<PricingCard
						title="Family Law"
						price="$650"
						desc="Nunc facilisis lacus ut lorem Aenean tincidunt metus a dui semper Sed congue nulla id fringilla Duis aliquet lacus facilisis urna Curabitur posuere lorem a sem pulvinar Suspendisse feugiat lorem nec Integer quis urna id mi maximus"
					/>
				</div>
			</div>
		</div>
	);
};

export default Pricing;
