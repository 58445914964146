import React from "react";
import Button from "./Button";

const ImageLeftContent = ({ title, desc, noLink, img }) => {
	return (
		<div className="w-full bg-troo-secondary grid grid-cols-1 md:grid-cols-2">
			<div className="w-full h-full">
				<img
					src={img}
					alt="placeholder"
					className="w-full h-full object-cover"
				/>
			</div>
			<div className="flex flex-col items-center justify-center p-8 md:p-14">
				<div className="flex flex-col items-start text-troo-stateTertiary gap-5">
					<h3>{title}</h3>
					<p>{desc}</p>
					{!noLink && (
						<Button className="flex btn-theme-primary">Read More</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default ImageLeftContent;
