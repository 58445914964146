import React from "react";
import logo from "./../../assets/images/logo-dark.svg";
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";

const Footer = () => {
	return (
		<div className="pb-14 pt-40 md:pt-60 w-full bg-troo-secondary border-t border-troo-primary">
			<div className="main-container-big flex flex-col md:flex-row md:items-center justify-between gap-10 md:gap-20">
				<div className="flex flex-col gap-[1px]">
					<div className="bg-troo-primary p-[30px] w-auto xl:w-[348px] flex flex-col items-start">
						<img src={logo} alt="logo" />
						<p className="text-troo-secondary mt-3">
							working on my article, Heather, was so patient. The sites my
							article was
						</p>
						<div className="flex items-center gap-3 mt-6">
							<div className="p-2 bg-troo-secondary text-troo-primary rounded-full">
								<FaFacebookF />
							</div>
							<div className="p-2 bg-troo-secondary text-troo-primary rounded-full">
								<FaTwitter />
							</div>
							<div className="p-2 bg-troo-secondary text-troo-primary rounded-full">
								<FaInstagram />
							</div>
							<div className="p-2 bg-troo-secondary text-troo-primary rounded-full">
								<FaLinkedinIn />
							</div>
						</div>
					</div>
					<div className="bg-troo-primary text-troo-secondary p-[30px] w-auto xl:w-[348px] flex flex-col items-start">
						<p>© 2022 TrooLaw. | All rights reserved</p>
					</div>
					<div className="grid xl:hidden mt-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 text-troo-stateTertiary">
						<div className="flex items-center gap-4 xl:justify-center">
							<p className="text-3xl">
								<HiOutlineLocationMarker />
							</p>
							<p>
								1 S Cherry Street, Winslow,
								<br />
								in,43598 United States
							</p>
						</div>
						<div className="flex items-center gap-4 xl:justify-center">
							<p className="text-3xl">
								<BsTelephone />
							</p>
							<p className="flex flex-col items-start">
								<span>(+642) 245 356 432</span>
								<span>(+642) 245 356 432</span>
							</p>
						</div>
						<div className="flex items-center gap-4 xl:justify-center">
							<p className="text-3xl">
								<MdOutlineMailOutline />
							</p>
							<p className="flex flex-col items-start">
								<span>hellosuppot@gmail.com</span>
								<span>help@.com</span>
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-6 w-auto">
					<div className="flex flex-col xl:flex-row gap-10 xl:gap-16 text-troo-stateTertiary xl:items-center xl:border-b border-troo-primary pb-8">
						<div className="flex gap-14">
							<div className="flex flex-col items-start gap-3 md:gap-6">
								<Link to="/">Home</Link>
								<Link to="/about-us">About Us</Link>
								<Link to="/service">Service</Link>
								<Link to="/our-work">Our Work</Link>
							</div>
							<div className="flex flex-col items-start gap-3 md:gap-6">
								<Link to="/blog">Blog</Link>
								<Link to="/contact-us">Contact Us</Link>
								<Link to="/security-and-privacy">Security & Privacy</Link>
								<Link to="/terms-and-conditions">Terms & Condition</Link>
							</div>
						</div>
						<div className="flex flex-col gap-3 box-border">
							<h3 className="text-troo-stateTertiary">
								Subscribe to Newsletter
							</h3>
							<div className="flex w-full box-border">
								<input
									type="text"
									placeholder="Email"
									className="border border-troo-stateTertiary bg-transparent w-full py-[8px] pl-4 text-sm px-8 md:text-base md:px-[22px] box-border"
								/>
								<button className="bg-troo-stateTertiary py-[8px] text-sm w-full md:w-auto md:text-base px-1 md:px-[22px] text-troo-secondary">
									Subscribe
								</button>
							</div>
						</div>
					</div>
					<div className="hidden xl:grid grid-cols-2 xl:grid-cols-3 gap-4 text-troo-stateTertiary">
						<div className="flex items-center gap-4 justify-center">
							<HiOutlineLocationMarker size={32} />
							<p>
								1 S Cherry Street, Winslow,
								<br />
								in,43598 United States
							</p>
						</div>
						<div className="flex items-center gap-4 justify-center">
							<BsTelephone size={32} />
							<p className="flex flex-col items-start">
								<span>(+642) 245 356 432</span>
								<span>(+642) 245 356 432</span>
							</p>
						</div>
						<div className="flex items-center gap-4 justify-center">
							<MdOutlineMailOutline size={32} />
							<p className="flex flex-col items-start">
								<span>hellosuppot@gmail.com</span>
								<span>help@.com</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
