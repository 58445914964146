import React from "react";
import ExperienceImg from "../../assets/images/insurance-law.svg";
import AwardsImg from "../../assets/images/awards-wins.svg";
import QualifiedAttorneys from "../../assets/images/qualified-attorneys.svg";
import ServiceCard from "../components/ServiceCard";
import LifeInsurance from "../../assets/images/life-insurance.jpg";
import ResearchBg from "../../assets/images/research-bg.jpg";
import ImageLeftContent from "../components/ImageLeftContent";

const PracticeDetails = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big flex h-full flex-col items-center gap-20">
				<ImageLeftContent
					img={LifeInsurance}
					desc="Vestibulum sed blandit turpis. Cras vitae risus in nunc euismod vehicula sit amet hendrerit nulla. In hac habitasse platea dictumst. Mauris ullamcorper dapibus magna vel tristique. Pellentesque in fringilla sem. Phasellus luctus volutpat congue. Sed faucibus egestas nisi. Integer volutpat velit lacus, vehicula congue lacus feugiat in. Sed tincidunt scelerisque elit. Vivamus sollicitudin condimentum tortor, accumsan eleifend eros. Suspendisse vel urna sed risus sodales iaculis in sed tortor. Maecenas sed diam nisi. Pellentesque non felis ullamcorper, lacinia magna et, porta enim. Donec ultrices gravida neque. Praesent posuere sem eget hendrerit malesuada. Morbi sed tempus eros, a convallis lorem. Sed efficitur porta purus. Aliquam facilisis vel eros iaculis convallis. "
					title="Insurance Law"
					noLink
				/>
				<div className="w-full h-full box-border grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
					<ServiceCard
						img={QualifiedAttorneys}
						title="Qualified Attorneys"
						noLink
					/>
					<ServiceCard img={AwardsImg} title="Awards Win" noLink />
					<ServiceCard img={ExperienceImg} title="Years of Experience" noLink />
				</div>
				<ImageLeftContent
					img={ResearchBg}
					desc="Vestibulum sed blandit turpis. Cras vitae risus in nunc euismod vehicula sit amet hendrerit nulla. In hac habitasse platea dictumst. Mauris ullamcorper dapibus magna vel tristique. Pellentesque in fringilla sem. Phasellus luctus volutpat congue. Sed faucibus egestas nisi. Integer volutpat velit lacus, vehicula congue lacus feugiat in. Sed tincidunt scelerisque elit."
					title="Research"
				/>
			</div>
		</div>
	);
};

export default PracticeDetails;
