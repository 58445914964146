import React from "react";
import AttorneyImg from "../../assets/images/attorney-avatar.jpg";
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
} from "react-icons/fa";

const TeamCardBig = () => {
	return (
		<div className="w-full grid grid-cols-1 sm:grid-cols-2">
			<div className="w-full h-full overflow-hidden">
				<img
					src={AttorneyImg}
					alt="Attorney Placeholder"
					className="w-full h-full object-cover"
				/>
			</div>
			<div className="flex flex-col">
				<div className="w-full border border-troo-primary h-full px-12 py-12 flex flex-col gap-4 items-start justify-center text-troo-stateTertiary">
					<h5>Chris Jariko</h5>
					<p>Seniour Attorny</p>
					<p>
						<span className="font-semibold">Practice Areas:</span> The Law
						Office of Jeannie D. Michalski
					</p>
					<p>
						<span className="font-semibold">Location:</span> 32/45 south east
						location bulgrid dreentown, London.
					</p>
					<p>
						<span className="font-semibold">Phone:</span> 33215-245-245
					</p>
					<p>
						<span className="font-semibold">Email:</span> robario@info.com
					</p>
				</div>
				<div className="border border-troo-primary bg-transparent py-[20px] flex justify-center text-troo-stateTertiary items-center gap-8">
					<FaFacebookF />
					<FaTwitter />
					<FaInstagram />
					<FaLinkedinIn />
				</div>
			</div>
		</div>
	);
};

export default TeamCardBig;
