import React from "react";
import { Link } from "react-router-dom";

const Button = ({ children, className, link, ...rest }) => {
	return (
		<button className={`${className}`} {...rest}>
			{link ? (
				<Link to={link} className="text-troo-stateTertiary z-20">
					{children}
				</Link>
			) : (
				<span className="text-troo-stateTertiary z-20">{children}</span>
			)}
			<div></div>
		</button>
	);
};

export default Button;
