import React from "react";
import Layout from "../shared/components/Layout";
import SubBanner from "../shared/components/SubBanner";
import ReviewSlider from "../shared/sections/ReviewSlider";
import OurClients from "../shared/sections/OurClients";
import ExpertTeamMultipleRows from "../shared/sections/ExpertTeamMultipleRows";

const Attorneys = () => {
	return (
		<Layout>
			<SubBanner title="Attorneys" className="bg-attorney-banner" />
			<ExpertTeamMultipleRows />
			<ReviewSlider />
			<OurClients />
		</Layout>
	);
};

export default Attorneys;
