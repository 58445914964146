import React from "react";
import Layout from "../shared/components/Layout";
import SubBanner from "../shared/components/SubBanner";
import ServiceMultipleRows from "../shared/sections/ServiceMultipleRows";
import PracticeDetails from "../shared/sections/PracticeDetails";

const SinglePracticeDetails = () => {
	return (
		<Layout>
			<SubBanner
				title="Insurance Law"
				className="bg-practice-details-placeholder"
			/>
			<PracticeDetails />
			<ServiceMultipleRows noLink={false} />
		</Layout>
	);
};

export default SinglePracticeDetails;
