import React from "react";
import Accordian from "../components/Accordian";

const WhyUs = () => {
	return (
		<div className="py-[100px] w-full bg-troo-stateSecondary">
			<div className="main-container-big grid grid-cols-1 md:grid-cols-2 h-full items-center gap-8">
				<div className="flex flex-col gap-2 items-start">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">
						We Are Best <br /> Why Choose Us
					</h2>
					<p className="text-troo-stateTertiary">
						It is a long established fact that a reader will be distracted by
						the readable content of a page when looking at its layout. The point
						of using Lorem Ipsum is that it has a more-or-less normal
						distribution of letters, as opposed to using 'Content here,
					</p>
				</div>
				<div className="flex flex-col gap-2">
					<Accordian
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
				</div>
			</div>
		</div>
	);
};

export default WhyUs;
