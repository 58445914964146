import React from "react";
import ReviewCard from "../components/ReviewCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

const ReviewSlider = () => {
	return (
		<div className="py-[100px] w-full bg-troo-stateSecondary">
			<div className="main-container-big w-full grid grid-cols-1 lg:grid-cols-3 h-full items-center gap-8">
				<div className="flex flex-col gap-2 items-start">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">
						We Are Best <br /> Why Choose Us
					</h2>
					<p className="text-troo-stateTertiary">
						It is a long established fact that a reader will be distracted by
						the readable content of a page when looking at its layout. The point
						of using Lorem Ipsum is that it has a more-or-less
					</p>
				</div>
				<div className="w-full h-full box-border lg:col-span-2">
					<Swiper
						slidesPerView={1}
						spaceBetween={16}
						pagination={{
							clickable: true,
						}}
						loop={true}
						autoplay={{
							delay: 2000,
							disableOnInteraction: false,
						}}
						breakpoints={{
							640: {
								slidesPerView: 1,
							},
							768: {
								slidesPerView: 1.5,
							},
							1024: {
								slidesPerView: 1.5,
							},
						}}
						className="mySwiper slider-dot-left first-active"
						modules={[Grid, Pagination, Autoplay]}
					>
						<SwiperSlide>
							<ReviewCard />
						</SwiperSlide>
						<SwiperSlide>
							<ReviewCard />
						</SwiperSlide>
						<SwiperSlide>
							<ReviewCard />
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default ReviewSlider;
