import React from "react";
import AboutImg from "../../assets/images/about.png";
import CourtOrder from "../../assets/images/court-order.svg";

const About = ({ withRadialProgress }) => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big-r-0 flex flex-col lg:flex-row items-center gap-8 justify-between">
				<div className="w-full lg:w-1/2 relative">
					<div className="text-center absolute right-3 bottom-3 text-troo-stateTertiary bg-troo-statePrimary px-7 py-4">
						<h3>1500+</h3>
						<h6>Successfull Cases</h6>
					</div>
					<img src={AboutImg} alt="about img" className="w-full" />
				</div>
				<div className="flex flex-col items-start gap-4 lg:gap-6 w-full lg:w-1/2 box-border px-8 lg:px-0 lg:pl-24">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> About TrooLaw
					</p>
					<h2 className="text-troo-stateTertiary">
						Donec eget pellentesque sem, eget ullamcorper nibh
					</h2>
					<p className="text-troo-stateTertiary">
						Nunc in congue nisl, a luctus orci. Aenean velit lorem, vestibulum
						viverra nisi at, blandit posuere mi. Nam nec purus in mi consectetur
						rhoncus id ut dui. Nulla interdum in est non posuere. Praesent
						porttitor sapien id malesuada tempor. Phasellus sit amet
					</p>
					<div className="flex flex-col gap-6">
						<div
							className={
								withRadialProgress
									? "flex flex-col md:flex-row items-start gap-4"
									: "flex w-full items-start gap-5"
							}
						>
							{withRadialProgress ? (
								<div className="">
									<div
										className="radial-progress bg-transparent text-troo-statePrimary"
										style={{ "--value": 90 }}
									>
										90%
									</div>
								</div>
							) : (
								<div className="w-[10%]">
									<img src={CourtOrder} alt="court order hammer" />
								</div>
							)}
							<div
								className={`flex ${
									!withRadialProgress && "w-[80%]"
								} flex-col gap-1 items-start text-troo-stateTertiary`}
							>
								<h5>Sed convallis in augue ac consequat</h5>
								<p>
									Donec eget pellentesque sem, eget ullamcorper nibh. Quisque ut
									ultricies ligula. Nulla gravida pulvinar ipsum vel
									condimentum. Quisque erat turpis, mattis sit amet
								</p>
							</div>
						</div>
						<div
							className={
								withRadialProgress
									? "flex flex-col md:flex-row items-start gap-4"
									: "flex w-full items-start gap-5"
							}
						>
							{withRadialProgress ? (
								<div className="">
									<div
										className="radial-progress bg-transparent text-troo-statePrimary"
										style={{ "--value": 70 }}
									>
										70%
									</div>
								</div>
							) : (
								<div className="w-[10%]">
									<img src={CourtOrder} alt="court order hammer" />
								</div>
							)}
							<div
								className={`flex ${
									!withRadialProgress && "w-[80%]"
								} flex-col gap-1 items-start text-troo-stateTertiary`}
							>
								<h5>Mauris pulvinar nisl justo, id imperdiet</h5>
								<p>
									Donec eget pellentesque sem, eget ullamcorper nibh. Quisque ut
									ultricies ligula. Nulla gravida pulvinar ipsum vel
									condimentum. Quisque erat turpis, mattis sit amet
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
