import React from "react";

const Quote = ({ text, author }) => {
	return (
		<div className="bg-troo-primary text-troo-secondary p-9 flex flex-col items-start gap-3">
			<p className="italic">“ {text} “</p>
			<h6>--- {author}</h6>
		</div>
	);
};

export default Quote;
