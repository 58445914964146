import React from "react";
import Avatar from "../../assets/images/team-avatar-1.png";

const ReviewCard = () => {
	return (
		<div className="relative bg-troo-primary text-troo-secondary px-8 py-8 md:px-10 md:py-12 flex flex-col items-start gap-6 lg:gap-8">
			<div className="absolute right-0 -bottom-8 w-16 overflow-hidden inline-block">
				<div className=" h-11 w-11 bg-troo-primary -rotate-45 transform origin-top-left"></div>
			</div>
			<p>
				‟Loved working with them! Great customer service and the writer working
				on my article, Heather, was so patient. The sites my article was
				published to were all official ones of NBC, FOX, etc which made this an
				amazing deal. Contrary to popular belief, Lorem Ipsum is not simply
				random text. It has roots in a piecBC, making it over 2000 years old”
			</p>
			<div className="flex items-center gap-3 lg:gap-6">
				<div className="w-12 h-12 lg:w-20 lg:h-20 aspect-square rounded-full overflow-hidden">
					<img
						src={Avatar}
						alt="avatar"
						className="w-full h-full object-cover"
					/>
				</div>
				<div className="">
					<p className="font-semibold">Sarah J. McCarthy</p>
					<p>Founder</p>
				</div>
			</div>
		</div>
	);
};

export default ReviewCard;
