import React from "react";
import Accordian from "../components/Accordian";

const FaqMultiColumn = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big grid grid-cols-1 md:grid-cols-2 h-full items-center gap-8">
				<div className="flex flex-col gap-4">
					<Accordian
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
						bgColor="bg-troo-stateSecondary"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
				</div>
				<div className="flex flex-col gap-4">
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
					<Accordian
						bgColor="bg-troo-stateSecondary"
						title="What do you need from me to write my article?"
						desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more"
					/>
				</div>
			</div>
		</div>
	);
};

export default FaqMultiColumn;
