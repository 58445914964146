import React from "react";
import ExperienceImg from "../../assets/images/insurance-law.svg";
import AwardsImg from "../../assets/images/awards-wins.svg";
import QualifiedAttorneys from "../../assets/images/qualified-attorneys.svg";
import ServiceCard from "../components/ServiceCard";

const ServiceMultipleRows = ({ noLink }) => {
	return (
		<div className="py-[100px] w-full bg-troo-stateSecondary">
			<div className="main-container-big flex h-full flex-col items-center gap-8">
				<div className="flex flex-col gap-2 text-center">
					<p className="text-troo-statePrimary uppercase">
						<span className="font-bold text-lg">//</span> Legal Practice
					</p>
					<h2 className="text-troo-stateTertiary">
						Lawyer Services For Public & Many Company
					</h2>
				</div>
				<div className="w-full h-full box-border grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
					<ServiceCard
						img={QualifiedAttorneys}
						title="Insurance Law"
						noLink={noLink}
					/>
					<ServiceCard noLink={noLink} img={AwardsImg} title="Education Law" />
					<ServiceCard
						noLink={noLink}
						img={ExperienceImg}
						title="Criminal Law"
					/>
					<ServiceCard
						noLink={noLink}
						img={QualifiedAttorneys}
						title="Commercial Law"
					/>
					<ServiceCard
						noLink={noLink}
						img={AwardsImg}
						title="Personal Injury"
					/>
					<ServiceCard
						noLink={noLink}
						img={ExperienceImg}
						title="Real Estate Law"
					/>
					<ServiceCard
						noLink={noLink}
						img={ExperienceImg}
						title="Divorce Law"
					/>
					<ServiceCard
						noLink={noLink}
						img={QualifiedAttorneys}
						title="Car Accident"
					/>
				</div>
			</div>
		</div>
	);
};

export default ServiceMultipleRows;
