import React from "react";
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
} from "react-icons/fa";

const TeamCard = ({ img, name, designation, desc }) => {
	return (
		<div className="flex flex-col hover:bg-troo-primary text-troo-stateTertiary hover:text-troo-secondary transition-colors hover:animate-smoothHeightFull">
			<div className="border flex flex-col lg:relative flex-start gap-6 px-5 py-6 lg:px-[40px] lg:py-[50px] border-troo-primary bg-transparent">
				<div className="w-24 lg:w-[164px] aspect-square lg:absolute right-7 -top-20">
					<img
						src={img}
						alt="Team Avatar 1"
						className="object-cover w-full h-full"
					/>
				</div>
				<div className="flex flex-col items-start gap-2">
					<h5>{name}</h5>
					<p>{designation}</p>
				</div>
				<p>{desc}</p>
			</div>
			<div className="border border-troo-primary bg-transparent py-[20px] flex justify-center items-center gap-8">
				<FaFacebookF />
				<FaTwitter />
				<FaInstagram />
				<FaLinkedinIn />
			</div>
		</div>
	);
};

export default TeamCard;
