import React from "react";
import Placeholder1 from "../../assets/images/placeholder-1.png";
import ConsultationForm from "../components/ConsultationForm";

const Consultation = ({ bgImg, noSideImg }) => {
	return (
		<div
			className={`py-[100px] w-full bg-troo-secondary ${bgImg} bg-right-top bg-no-repeat`}
		>
			<div
				className={`main-container-big grid grid-cols-1 md:grid-cols-2 ${
					noSideImg && "gap-8"
				}`}
			>
				{noSideImg ? (
					<div className="w-full flex flex-col items-start justify-center h-full">
						<div className="flex flex-col gap-2">
							<p className="text-troo-statePrimary uppercase">
								<span className="font-bold text-lg">//</span> THERE ARE MANY
								VARIATIONS
							</p>
							<h1 className="text-troo-stateTertiary">
								Contrary to popular belief, Lorem Ipsum is not simply random
							</h1>
							<p className="text-troo-stateTertiary">
								It is a long established fact that a reader will be distracted
								by the readable content of a page when looking at its layout.
								The point of using Lorem Ipsum is that it has a more-or-less
								normal
							</p>
						</div>
					</div>
				) : (
					<div className="w-full hidden md:block h-full">
						<img
							src={Placeholder1}
							alt="placeholder"
							className="w-full h-full object-cover"
						/>
					</div>
				)}
				<ConsultationForm />
			</div>
		</div>
	);
};

export default Consultation;
