import React from "react";
import ImageLeftContent from "../components/ImageLeftContent";
import ImageRightContent from "../components/ImageRightContent";
import FamilyImg from "../../assets/images/family.png";
import IdentifyTheftImg from "../../assets/images/identify-theft.png";
import LegalAdviceImg from "../../assets/images/legal-advice.png";

const ImageContentPosts = () => {
	return (
		<div className="bg-troo-stateSecondary">
			<div className="main-container-big flex flex-col items-center py-[50px] bg-troo-stateSecondary">
				<ImageLeftContent
					img={FamilyImg}
					desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything"
					title="Family Law"
				/>
				<ImageRightContent
					img={IdentifyTheftImg}
					desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything"
					title="Identity Theft"
				/>
				<ImageLeftContent
					img={LegalAdviceImg}
					desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything"
					title="Legal Advice"
				/>
			</div>
		</div>
	);
};

export default ImageContentPosts;
