import React from "react";

const AttorneyList = () => {
	return (
		<div className="text-troo-stateTertiary border border-troo-primary w-full p-10 flex flex-col gap-4">
			<h5>Our Attorneys</h5>
			<div className="flex flex-col w-full items-start">
				<span className="w-full text-start py-2 border-b border-troo-stateTertiary border-opacity-25">
					Alina Kevin
				</span>
				<span className="w-full text-start py-3 border-b border-troo-stateTertiary border-opacity-25">
					Brean Lanthe
				</span>
				<span className="w-full text-start py-3 border-b border-troo-stateTertiary border-opacity-25">
					Mary J. Palmer
				</span>
				<span className="w-full text-start py-3 border-b border-troo-stateTertiary border-opacity-25">
					Joshua P. Evans
				</span>
				<span className="w-full text-start py-3 border-b border-troo-stateTertiary border-opacity-25">
					Cora D. Kline
				</span>
				<span className="w-full text-start py-3 border-b border-troo-stateTertiary border-opacity-25">
					Jonathan M.
				</span>
			</div>
		</div>
	);
};

export default AttorneyList;
